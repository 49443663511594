<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex xs12 sm8 md6 xl4>
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 class="text-xs-center mb-4">
              <v-progress-circular :size="100" :width="7" color="accent" indeterminate></v-progress-circular>
            </v-flex>
            <v-flex xs12 class="text-xs-center mb-4">
              <h2 class="headline mb-4">処理中です！</h2>
              <p class="body-2">処理が完了するまで、もうしばらくお待ちください。</p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      services: {
        facility: process.env.VUE_APP_SERVICE_HOST_FACILITY,
        stock: process.env.VUE_APP_SERVICE_HOST_STOCK,
        groupBooking: process.env.VUE_APP_SERVICE_HOST_GROUP_BOOKING,
      },
    };
  },
  created() {
    this.redirect();
  },
  methods: {
    redirect() {
      const { query } = this.$route;
      const { service, team } = query;
      const { go400 } = this;
      if (!service || !team) {
        go400();
        return;
      }

      const serviceHost = this.services[service];
      const re = /^([a-zA-Z0-9][a-zA-Z0-9-_]+)$/;
      const result = re.test(team);
      if (!serviceHost || !result) {
        go400();
        return;
      }

      const mode = process.env.VUE_APP_MODE;
      const target =
        mode === 'development' ? `http://${serviceHost}/signin/` : `https://${team}.${serviceHost}/signin/`;
      window.location.replace(target);
    },
    go400() {
      this.$router.push({ name: '400' });
    },
  },
};
</script>
